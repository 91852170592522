import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

// credit: https://codepen.io/desandro/pen/MwJoZQ

var elem = document.querySelector('.masonry')
var masonry = new Masonry(elem, {
    itemSelector: '.masonry-item',
    percentPosition: true
});

imagesLoaded( elem ).on( 'progress', function() {
    // layout Masonry after each image loads
    masonry.layout();
});